import { ajax, ajaxFail } from '../utils/functions'

const prefix = 'user'

export default {
  async getFull (id) {
    try {
      const res = await ajax().get(`/${prefix}/${id}`)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async get () {
    try {
      const res = await ajax().get(`/${prefix}/me`)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async update (payload = {}) {
    try {
      const res = await ajax().put(`/${prefix}/me`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async delete (payload = {}) {
    try {
      const res = await ajax().post(`/${prefix}/me`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async changePassword (payload = {}) {
    try {
      const res = await ajax().post(`/${prefix}/me/password`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  }
}
