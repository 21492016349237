import { ajax, ajaxFail } from '../utils/functions'

const prefix = 'work'

export default {
  async gets (params = {}) {
    try {
      const res = await ajax().get(`/${prefix}`, { params })

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async create (payload = {}) {
    try {
      const res = await ajax().post(`/${prefix}`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async get (id) {
    try {
      const res = await ajax().get(`/${prefix}/${id}`)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async update (id, payload = {}) {
    try {
      const res = await ajax().put(`/${prefix}/${id}`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async delete (id) {
    try {
      const res = await ajax().delete(`/${prefix}/${id}`)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  }
}
