import { ajax, ajaxFail } from '../utils/functions'

const prefix = 'project/tech'

export default {
  async gets (idProject, params = {}) {
    try {
      const res = await ajax().get(`/${prefix}/${idProject}`, { params })

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async create (idProject, payload = {}) {
    try {
      const res = await ajax().post(`/${prefix}/${idProject}`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async get (idProject, id) {
    try {
      const res = await ajax().get(`/${prefix}/${idProject}/${id}`)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async update (idProject, id, payload = {}) {
    try {
      const res = await ajax().put(`/${prefix}/${idProject}/${id}`, payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async delete (idProject, id) {
    try {
      const res = await ajax().delete(`/${prefix}/${idProject}/${id}`)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  }
}
