<template>
  <q-layout view="hHh Lpr lFf">
    <q-header v-if="profile" elevated class="glossy">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title class="cursor-pointer" @click="goHome">
          {{ appTitle }}
        </q-toolbar-title>

        <q-btn color="white" flat @click="logout">Logout</q-btn>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="profile"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2 q-pt-lg"
    >
      <q-list>
        <q-item
          v-for="(m, i) in menus"
          :key="'menu-' + i"
          clickable
          :active="selectedMenu === i"
          @click="$router.push(m.to); selectedMenu = i"
        >
          <q-item-section v-if="m.icon" avatar>
            <q-icon :name="m.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ m.title }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container class="q-pa-md-0 doc-container">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'App',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const selectedMenu = ref(null)

    const menus = ref([{
      title: 'Profile',
      icon: 'manage_accounts',
      to: '/profile'
    }, {
      title: 'Educations',
      icon: 'school',
      to: '/educations'
    }, {
      title: 'Works',
      icon: 'engineering',
      to: '/works'
    }, {
      title: 'Skills',
      icon: 'stars',
      to: '/skills'
    }, {
      title: 'Projects',
      icon: 'auto_stories',
      to: '/projects'
    }, {
      title: 'Certificates',
      icon: 'card_membership',
      to: '/certificates'
    }, {
      title: 'Contacts',
      icon: 'contacts',
      to: '/contacts'
    }])

    onMounted(async () => {
      store.dispatch('setOptions')

      if (localStorage.getItem('token')) {
        const { success, data } = await store.dispatch('setProfile')

        if (success) {
          await store.dispatch('setProfileFull', data?.id)

          if (route.fullPath === '/') {
            router.push('/dashboard')
          } else {
            selectedMenu.value = menus.value.map(e => e.to).indexOf(route.path)
          }
        }
      }
    })

    const logout = () => {
      localStorage.removeItem('token')
      location.href = '/'
    }

    const profile = computed(() => store.state.profile)

    const goHome = () => {
      router.push('/dashboard')
    }

    const appTitle = process.env.VUE_APP_TITLE

    return {
      leftDrawerOpen: ref(false),
      profile,
      logout,
      menus,
      goHome,
      selectedMenu,
      appTitle
    }
  }
}
</script>
