import { ajax, ajaxFail } from '../utils/functions'

export default {
  async login (payload = {}) {
    try {
      const res = await ajax().post('/auth/login', payload)

      if (res.data?.success) {
        localStorage.setItem('token', res.data?.data)
      }

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  async register (payload = {}) {
    try {
      const res = await ajax().post('/auth/register', payload)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  },
  logout () {
    localStorage.removeItem('token')
  }
}
