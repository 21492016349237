import auth from './auth'
import certificate from './certificate'
import contact from './contact'
import education from './education'
import profile from './profile'
import project from './project'
import projectMedia from './projectMedia'
import projectTech from './projectTech'
import skill from './skill'
import work from './work'
import workDetail from './workDetail'
import options from './options'

export default {
  auth,
  certificate,
  contact,
  education,
  profile,
  project,
  projectMedia,
  projectTech,
  skill,
  work,
  workDetail,
  options
}
