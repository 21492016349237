import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'About'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: 'Dashboard'
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Profile'
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/educations',
    name: 'Educations',
    meta: {
      title: 'Educations'
    },
    component: () => import(/* webpackChunkName: "education" */ '../views/Education.vue')
  },
  {
    path: '/works',
    name: 'Works',
    meta: {
      title: 'Works'
    },
    component: () => import(/* webpackChunkName: "work" */ '../views/Work.vue')
  },
  {
    path: '/works/:id/details',
    name: 'Work Details',
    meta: {
      title: 'Work Details'
    },
    component: () => import(/* webpackChunkName: "workDetail" */ '../views/WorkDetail.vue')
  },
  {
    path: '/skills',
    name: 'Skills',
    meta: {
      title: 'Skills'
    },
    component: () => import(/* webpackChunkName: "skill" */ '../views/Skill.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: {
      title: 'Projects'
    },
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue')
  },
  {
    path: '/projects/:id/techs',
    name: 'Project Techs',
    meta: {
      title: 'Project Techs'
    },
    component: () => import(/* webpackChunkName: "projectTech" */ '../views/ProjectTech.vue')
  },
  {
    path: '/projects/:id/medias',
    name: 'Project Medias',
    meta: {
      title: 'Project Medias'
    },
    component: () => import(/* webpackChunkName: "projectMedia" */ '../views/ProjectMedia.vue')
  },
  {
    path: '/certificates',
    name: 'Certificates',
    meta: {
      title: 'Certificates'
    },
    component: () => import(/* webpackChunkName: "certificate" */ '../views/Certificate.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: {
      title: 'Contacts'
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const APP_TITLE = process.env.VUE_APP_TITLE ?? 'Resume Online Portal'

router.beforeEach((to, from, next) => {
  document.title = `${APP_TITLE ? APP_TITLE + ' - ' : ''}${to.meta.title}`
  next()
})

export default router
