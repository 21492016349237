import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import './styles/font.scss'
import './styles/global.scss'
import { ajax, validationRules } from './utils/functions'
import api from './api'
import dialogConfirm from './components/dialogConfirm.vue'

const app = createApp(App)

app.use(Quasar, quasarUserOptions).use(store).use(router)

app.config.globalProperties.$ajax = ajax()
app.config.globalProperties.$api = api
app.config.globalProperties.$rules = validationRules

app.component('dialog-confirm', dialogConfirm)

app.mount('#app')
